import init3QVideo from './initThreeqVideo'

(function() {
    const videos = document.querySelectorAll('[data-threeq]')

    const _js3qi = setInterval(function() {
        if(typeof js3q != 'undefined') {
            clearInterval(_js3qi)
            videos.forEach(video => {
                init3QVideo(video)
            })
        }
    }, 200)
})()
