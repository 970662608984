export default (video) => {
    const config = JSON.parse(decodeURI(video.dataset.threeq))
    const playerId = 'video-3q-' + Date.now()

    video.setAttribute('id', playerId)
    new js3q({
        dataid: config.playoutId,
        container: playerId,
        cast: false,
        key: config.playoutKey,
        timestamp: Date.now()
    })
}
